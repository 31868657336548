<template>
    <div class="py-10 overflow-hidden">
        <div class="relative mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <div class="hidden 2xl:inline-block relative">
                <h3 class="text-center text-3xl leading-8 font-extrabold tracking-tight text-jiruto-prelude sm:text-4xl sm:leading-10">
                    Organized. Structured. Ordered. Flexible!
                </h3>
                <p class="mt-4 max-w-3xl mx-auto  text-center text-xl leading-7 text-jiruto-prelude">
                    Use the structure of kanban boards for curriculums, ideas, features, tasks, resources, and so much more
                </p>
            </div>

            <div class="relative mt-3 lg:mt-7 flex flex-col xl:grid xl:grid-cols-2 lg:gap-8 items-start">
                <div class="relative bg-gray">
                    <div class="hidden 2xl:block">
                        <h4 class="text-2xl leading-8 font-extrabold text-white tracking-tight sm:text-3xl sm:leading-9">
                            Organization!
                        </h4>
<!--                        <p class="mt-3 text-lg leading-7 text-gray-300">-->
<!--                            It's the future-->
<!--                        </p>-->
                    </div>
                    <div class="block 2xl:hidden">
                        <h4 class="text-2xl leading-8 font-extrabold text-jiruto-prelude tracking-tight sm:text-3xl sm:leading-9">
                            Organized. Structured. Ordered. Flexible!
                        </h4>
                        <p class="mt-3 text-lg leading-7 text-jiruto-prelude">
                            Use the structure of kanban boards for curriculums, ideas, features, tasks, resources, and so much more
                        </p>
                    </div>

                    <ul class="mt-5">
                        <li class="featureCard ">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <!--                                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
                                        <!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>-->
                                        <!--                                        </svg>-->
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </div>
                                </div>
                                <div class="ml-4">
                                    <h5 class="text-lg leading-6 font-bold text-gray-700 text-left">Resources</h5>
                                    <p class="mt-2 text-base leading-6 text-gray-500 text-left">
                                        Use community Kanban boards to gather and order resources. Classify them into levels and subtopics.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="mt-5 featureCard ">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <!--                                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
                                        <!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>-->
                                        <!--                                        </svg>-->
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 14l9-5-9-5-9 5 9 5z"></path><path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path></svg>
                                    </div>
                                </div>
                                <div class="ml-4">
                                    <h5 class="text-lg leading-6 font-bold text-gray-700 text-left">Always Up-To-Date</h5>
                                    <p class="mt-2 text-base leading-6 text-gray-500 text-left">
                                        No more data in old and outdated excel sheets or static lists. Edit and update the boards as you please.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="mt-5 hidden 2xl:inline-block featureCard">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="ml-4">
                                    <h5 class="text-lg leading-6 font-bold text-gray-700 text-left">Increased Productivity</h5>
                                    <p class="mt-2 text-base leading-6 text-gray-500 text-left">
                                        Kanban boards are good for brainstorming and tossing ideas. It's a place to record our thoughts and convert them to tasks.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <!--                        <li class="mt-10 hidden 2xl:inline-block">-->
                        <!--                            <div class="flex">-->
                        <!--                                <div class="flex-shrink-0">-->
                        <!--                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">-->
                        <!--                                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
                        <!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>-->
                        <!--                                        </svg>-->
                        <!--                                    </div>-->
                        <!--                                </div>-->
                        <!--                                <div class="ml-4">-->
                        <!--                                    <h5 class="text-lg leading-6 font-medium text-white">Easy and straight forward</h5>-->
                        <!--                                    <p class="mt-2 text-base leading-6 text-gray-300">-->
                        <!--                                        Design learning communities with little effort. Add links, levels, description, and special explanations, all in an intuitive interface.-->
                        <!--                                    </p>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </li>-->
                    </ul>
                </div>

                <div class="mt-5 w-full relative lg:mt-0">
<!--                    <img class="hidden 2xl:inline-block relative mx-auto rounded-lg" width="490" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fgeneral-scrrenshot.png?alt=media&token=5cad985f-c207-4ebc-aaa0-5b6059c155cb" alt="" />-->
                    <img class="relative mx-auto rounded-lg opacity-80" width="490" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fbeginner-screenshot.png?alt=media&token=cc5d1c14-1900-4d89-b1ff-8abf520946d5" alt="" />
                    <img class="relative mx-auto mt-4 rounded-lg opacity-80" width="490" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fintermediate-screenshot.png?alt=media&token=aee762da-5352-4746-9e0e-3fe6f1aed524" alt="" />

                </div>
            </div>

<!--            <div class="hidden relative mt-12 sm:mt-16 lg:mt-24">-->
<!--                <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">-->
<!--                    <div class="lg:col-start-2">-->
<!--                        <h4 class="text-2xl leading-8 font-extrabold text-white tracking-tight sm:text-3xl sm:leading-9">-->
<!--                            Immerse yourself in resources-->
<!--                        </h4>-->
<!--                        <p class="mt-3 text-lg leading-7 text-gray-300">-->
<!--                            Get exposure to the right courses, blogs, forums, YouTube channels, and people in your area of interest-->
<!--                        </p>-->

<!--                        <ul class="mt-10">-->
<!--                            <li>-->
<!--                                <div class="flex">-->
<!--                                    <div class="flex-shrink-0">-->
<!--                                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">-->
<!--                                            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
<!--                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/>-->
<!--                                            </svg>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="ml-4">-->
<!--                                        <h5 class="text-lg leading-6 font-medium text-white">Collaborate globally</h5>-->
<!--                                        <p class="mt-2 text-base leading-6 text-gray-300">-->
<!--                                            Join forces with other educators to build the best community for your field. Curate the most suitable resources from around the web based on your shared experience.-->
<!--                                        </p>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                            <li class="mt-10">-->
<!--                                <div class="flex">-->
<!--                                    <div class="flex-shrink-0">-->
<!--                                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">-->
<!--                                            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
<!--                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"/>-->
<!--                                            </svg>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="ml-4">-->
<!--                                        <h5 class="text-lg leading-6 font-medium text-white">Free Forever</h5>-->
<!--                                        <p class="mt-2 text-base leading-6 text-gray-300">-->
<!--                                            Open education is as it sounds, open to all and free. Searching, viewing and designing public communities will remain free forever.-->
<!--                                        </p>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                            <li class="mt-10">-->
<!--                                <div class="flex">-->
<!--                                    <div class="flex-shrink-0">-->
<!--                                        <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">-->
<!--                                            <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
<!--                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>-->
<!--                                            </svg>-->
<!--                                        </div>-->
<!--                                    </div>-->
<!--                                    <div class="ml-4">-->
<!--                                        <h5 class="text-lg leading-6 font-medium text-white">Easy and straight forward</h5>-->
<!--                                        <p class="mt-2 text-base leading-6 text-gray-300">-->
<!--                                            Design learning communities with little effort. Add links, levels, description, and special explanations, all in an intuitive interface.-->
<!--                                        </p>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->

<!--                    <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">-->
<!--                        <img class="relative mx-auto rounded-lg" width="490" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fintermediate-screenshot.png?alt=media&token=aee762da-5352-4746-9e0e-3fe6f1aed524" alt="" />-->
<!--                        <img class="relative mx-auto mt-4 rounded-lg" width="490" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fadvanced-screenshot.png?alt=media&token=7f4894e8-9d99-4d7c-977a-c9324e465070" alt="" />-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "KanbanCuration"
    }
</script>

<style scoped>
    .featureCard {
        @apply bg-white bg-opacity-80 p-3 rounded-lg
    }

</style>
