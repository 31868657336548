<template>
    <flipping-card-shell>
        <template v-slot:cardFront>
            <div class="w-full h-full p-5 items-center flex flex-col justify-center bg-jiruto-nurpish">
                <div class="absolute w-full h-full top-0 left-0 z-20 flex flex-col justify-center">
                    <div class="absolute w-full h-full top-0 left-0 z-20 flex flex-col mt-20 lg:mt-0 lg:justify-center">
                        <span class="text-6xl sm:text-7xl lg:text-8xl xl:text-9xl text-purple-600 font-bold text-center"> Reviews </span>
                    </div>

                </div>

                <div class="w-full h-full flex items-end lg:items-start sm:absolute object-contain lg:object-contain max-w-md sm:max-w-xl md:max-w-xl lg:max-w-xl xl:max-w-xl 2xl:max-w-2xl flex bottom-0 right-0 lg:top-0 sm:right-0 z-10">
                    <img class="absolute object-contain flex items-end bottom-0 sm:top-0 right-0 sm:relative"
                             src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Flanding-page%2FmanReviewNoBackground.png?alt=media&token=fd429d1a-48b5-4790-b062-137ce36937e9" alt="">
                </div>
            </div>
        </template>

        <template v-slot:cardBack>
            <div class="w-full hidden sm:block h-full p-5 items-center flex flex-col justify-center bg-jiruto-nurpish">
                <div class="absolute top-0 right-0 p-5 z-20 flex flex-col justify-center">
                    <div class="w-full h-full flex flex-col items-center">
                        <!--                        <span class="text-6xl sm:text-7xl lg:text-7xl xl:text-9xl text-jiruto-iris font-bold text-center mt-5"> Community Guidance </span>-->
                        <!--                        <span class="text-6xl sm:text-7xl lg:text-7xl xl:text-9xl text-jiruto-froly font-bold text-center mt-2">-->
                        <!--                             <it></it> makes all the difference-->
                        <!--                        </span>-->
                        <!--                        <problem-description>-->
                        <!--                        </problem-description>-->
                        <div class="relative">
                            <div class="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                                <h2 class="text-3xl mt-10 sm:mt-0 text-black font-extrabold tracking-tight sm:text-4xl lg:text-5xl xl:text-6xl" > Hear The People</h2>
                                <p class="text-xl text-gray-200"> Who never heard of us, but if they did, they would probably say:</p>
                            </div>
                            <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-5">
                                <div v-for="(quote, index) in quotes" :key="index"  class="relative bg-opacity-80 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                    <div class="flex-shrink-0">
                                        <img class="h-12 w-12 rounded-full" :src="quote.avatarUrl" alt="">
                                    </div>
                                    <div class="flex-1 min-w-0">
                                        <div class="focus:outline-none">
                                            <span class="absolute inset-0" aria-hidden="true"></span>
                                            <p class="text-sm text-left font-medium text-gray-900">
                                                {{ quote.nameAndTitle }}
                                            </p>
                                            <p class="text-xs lg:text-sm text-left text-gray-500 truncate">
                                                {{ quote.quote }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="absolute object-contain lg:object-contain lg:max-w-xl xl:max-w-xl 2xl:max-w-3xl  flex bottom-0 left-0 z-10">
                    <img class="object-contain"
                         src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Flanding-page%2FwomanReviewNoBackground.png?alt=media&token=22417e5e-8af1-468e-bd1c-e0dcf79a762c" alt="">
                </div>
            </div>

            <div class="flex flex-col h-full w-full bg-jiruto-nurpish rounded-lg shadow-lg overflow-hidden block sm:hidden">
                <div class="flex-shrink-0">
                    <img class="h-48 w-full object-contain" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Flanding-page%2FwomanReviewNoBackground.png?alt=media&token=22417e5e-8af1-468e-bd1c-e0dcf79a762c" alt="">
                </div>
                <div class="flex-1 bg-jiruto-nurpish px-6 py-1 flex flex-col justify-between">
                    <div class="flex-1">
                        <div href="#" class="block">
                            <p class="text-xl font-semibold text-gray-900">
                                Hear The People
                            </p>
                            <p class="mt-3 text-base text-gray-600">
                                We can't say that <span class="text-purple-500">Paul Graham</span>  and <span class="text-purple-500">Mark Andreessen</span> said that <span class="text-purple-500">Jiruto</span> is the next big thing.
                            </p>
                            <p class="mt-3 text-base text-gray-600">
                                But we also can't say that they didn't. Who knows.
                            </p>
                            <p class="mt-6 text-sm text-gray-500">
                               Check out our desktop version to see what other leaders didn't say about us.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </template>
    </flipping-card-shell>
</template>

<script>
    import FlippingCardShell from "@/pages/landing/components/FlippingCardShell";
    export default {
        name: "AmazingReviews",
        components: {FlippingCardShell},
        data(){
            return {
                quotes: [
                    {
                        nameAndTitle: "Shonda Rhimes / TV Producer",
                        quote: "Oh, this is Emmy material...",
                        avatarUrl: "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Flanding-page%2Freviews%2FshondaRhimes.jpg?alt=media&token=4f72a36f-9458-47fa-9e26-8987a08ca046",
                    },
                    {
                        nameAndTitle: "Elon Musk / CEO @ many many companies",
                        quote: "It's better than my cars. And nothing is better than my cars",
                        avatarUrl: "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Flanding-page%2Freviews%2Felon.jpg?alt=media&token=5918eb59-acc9-4903-a1f4-44875be8f26b",
                    },
                    {
                        nameAndTitle: "Tim Cook / CEO @ Apple",
                        quote: "It's so beautiful, is Jony Ivy doing a side gig here?",
                        avatarUrl: "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Flanding-page%2Freviews%2FtimCook.jpg?alt=media&token=9f851db0-4a22-4b92-afcc-9db9bb89d1c3",
                    },
                    {
                        nameAndTitle: "Moses / Community Manager @ Bible",
                        quote: "It would have taken us less than 40 years if we had access to Jiruto. Mos Def",
                        avatarUrl: "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Flanding-page%2Freviews%2FmosesBrit.jpg?alt=media&token=d61919be-32c9-4536-a96b-bd0ce8fe63a1",
                    },
                    {
                        nameAndTitle: "Paul Graham / Manager @ YC",
                        quote: "Can you please answer my calls? We want you to enter the next YC batch",
                        avatarUrl: "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Flanding-page%2Freviews%2FpaulGraham.jpg?alt=media&token=2b40483f-92ba-40a8-ba5f-b36f11c4a0bd",
                    },
                    {
                        nameAndTitle: "davie504 / Italian YouTuber",
                        quote: "This deserves a SLAP",
                        avatarUrl: "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Flanding-page%2Freviews%2Fdavie504.jpg?alt=media&token=294694c3-3ec8-4630-ad5e-01609ea29f1a",
                    },
                    {
                        nameAndTitle: "Italian Spiderman",
                        quote: "Rispetti le donne!",
                        avatarUrl: "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Flanding-page%2Freviews%2FitalianSpiderman.jpg?alt=media&token=fd2b3ad4-9100-4cc6-8314-fc423e7984f4",
                    },
                    {
                        nameAndTitle: "Marc andreessen / General Manager @ a16z",
                        quote: "Paul, did you get a hold of them? I'm also trying",
                        avatarUrl: "https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Flanding-page%2Freviews%2FmarcAndreesen.jpg?alt=media&token=65de1e5b-b40e-4f15-947c-7e0ee04c6a1c",
                    },

                ]
            }
        }
    }
</script>

<style scoped>

</style>
