<template>
    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8 sm:mt-5 mx-2">
        <main class="mx-auto max-w-screen-xl px-2 sm:px-6">
            <div class="text-center">
                <h2 class="text-3xl sm:text-7xl lg:text-8xl xl:text-9xl text-jiruto-main font-bold text-center">
                    <span class="text-jiruto-menu">TO YOUR DREAMS</span>
                </h2>
                <p class="mt-4 max-w-md mx-auto text-base text-gray-200 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">

                    <span class="text-jiruto-zotMain">Start a startup &middot; </span>
                    <span class="text-jiruto-zotMain">Switch a career &middot; </span>
                    <span class="text-jiruto-zotMain">Speak another language &middot; </span>
<!--                    <span class="text-jiruto-success">Enrich your life. </span>-->
                    <span class="text-jiruto-zotMain">Dance like there's no tomorrow &middot; </span>
                    <span class="text-jiruto-zotMain">Paint your loved ones &middot; </span>
                    <span class="text-jiruto-zotMain">Land a dream job &middot; </span>
                    <span class="text-jiruto-zotMain">Create a sword &middot; </span>
                    <span class="hidden sm:inline-block text-jiruto-zotMain">Become an influencer &middot; </span>
                    <span class="text-jiruto-zotMain">Build a personal website &middot; </span>
                    <span class="text-jiruto-zotMain">Write a novel &middot; </span>
                    <span class="text-jiruto-zotMain">Construct a tree house &middot; </span>
                    <span class="text-jiruto-zotMain">Design your wedding &middot; </span>
                    <span class="text-jiruto-zotMain">Produce a song </span>
                </p>

                <h2 class="text-2xl tracking-tight mt-2 sm:mt-10 leading-10 font-extrabold text-red-800 sm:text-5xl sm:leading-none md:text-5xl">
                    But there can be obstacles along the way
                </h2>
            </div>
        </main>
    </div>


</template>

<script>
    export default {
        name: "YourDream"
    }
</script>

<style scoped>

</style>
