<template>
    <div class="relative w-full h-full bg-jiruto-main">
        <learning-community></learning-community>

        <dream-doors></dream-doors>

        <overcome-obstacles></overcome-obstacles>

        <live-sessions></live-sessions>

        <open-discussions></open-discussions>

        <KanbanPower></KanbanPower>

        <done-with-love></done-with-love>

        <amazing-reviews></amazing-reviews>

    </div>
</template>

<script>
    import LearningCommunity from "@/pages/landing/components/LearningCommunity";
    import OvercomeObstacles from "@/pages/landing/components/OvercomeObstacles";
    import LiveSessions from "@/pages/landing/components/LiveSessions";
    import DoneWithLove from "@/pages/landing/components/DoneWithLove";
    import OpenDiscussions from "@/pages/landing/components/OpenDiscussions";
    import KanbanPower from "@/pages/landing/components/KanbanPower";
    import DreamDoors from "@/pages/landing/components/DreamDoors";
    import AmazingReviews from "@/pages/landing/components/AmazingReviews";
    export default {
        name: "LP2",
        components: {
            AmazingReviews,
            DreamDoors,
            KanbanPower, OpenDiscussions, DoneWithLove, LiveSessions, OvercomeObstacles, LearningCommunity},
        data(){
            return {
                showDetails: false,
            }
        },
        methods: {
            showDetailsPressed(){
                console.log("Pressed!")
                this.showDetails = !this.showDetails
            }
        }
    }
</script>

<style scoped>
    .scene {
        perspective: 8000px;
    }

    .card-container {
        /*width: 100%;*/
        /*height: 100%;*/
        transition: transform 1s;
        transform-style: preserve-3d;
        cursor: pointer;
        position: relative;
    }

    .card-container.is-flipped {
        transform: rotateY(180deg);
    }

    .card-face {
        position: absolute;
        /*width: 100%;*/
        /*height: 100%;*/
        /*line-height: 260px;*/
        color: white;
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(1px);

    }

    .card-back {
        transform: rotateY(-180deg) translateZ(1px);
        -webkit-backface-visibility: hidden;
        -webkit-transform: rotateY(180deg) translateZ(1px);
    }

</style>
