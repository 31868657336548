<template>
    <flipping-card-shell>
        <template v-slot:cardFront>
            <div class="w-full h-full p-5 items-center flex flex-col justify-center bg-jiruto-froly">
                <div class="absolute w-full h-full top-0 left-0 z-20 flex flex-col justify-center">
                    <div class="absolute w-full h-full top-0 left-0 z-20 flex flex-col mt-20 lg:mt-0 lg:justify-center">
                        <span class="text-6xl sm:text-7xl lg:text-8xl xl:text-9xl text-red-700 font-bold text-center"> Done With Love </span>
<!--                        <span class="text-7xl lg:text-7xl xl:text-9xl text-white font-bold text-center mt-2">-->
<!--                            Learning is tough love-->
<!--                        </span>-->
                    </div>

                </div>

                <div class="w-full h-full flex items-end sm:absolute object-contain max-w-md sm:max-w-xl md:max-w-xl lg:max-w-xl xl:max-w-lg 2xl:max-w-2xl flex bottom-0 left-0 z-10">
                    <img class="absolute object-contain flex items-end bottom-0 left-0 sm:relative bg-jiruto-froly"
                         src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FDoneWithLove.jpeg?alt=media&token=7053df8d-8096-4149-8a34-3840e5ed0240" alt="">
                </div>
            </div>
        </template>

        <template v-slot:cardBack>
            <div class="w-full hidden sm:block h-full p-5 items-center flex flex-col justify-center bg-jiruto-froly">
                <div class="absolute top-0 right-0 p-5 z-20 flex flex-col justify-center">
                    <div class="w-full h-full flex flex-col items-center">
<!--                        <span class="text-6xl sm:text-7xl lg:text-7xl xl:text-9xl text-jiruto-iris font-bold text-center mt-5"> Community Guidance </span>-->
<!--                        <span class="text-6xl sm:text-7xl lg:text-7xl xl:text-9xl text-jiruto-froly font-bold text-center mt-2">-->
<!--                             <it></it> makes all the difference-->
<!--                        </span>-->
<!--                        <problem-description>-->
<!--                        </problem-description>-->

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div class="relative">
                            <!-- This example requires Tailwind CSS v2.0+ -->
                            <div class="bg-opacity-80 rounded-lg">
                                <div class="max-w-3xl mx-auto text-center py-8 px-4 sm:py-8 sm:px-6 lg:px-8">
                                    <h2 class="text-4xl sm:text-5xl font-extrabold text-white ">
                                        <span class="block ">We Love What We Do.</span>
                                        <span class="block">We Believe In Communities.</span>
                                    </h2>
                                    <p class="mt-4 text-lg leading-6 text-indigo-200">Belonging to a good community gives us strength and vigor. It's also a lot of fun. If you too feel the power of communities, let us know!</p>
                                    <a href="#" class="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto">
                                        Sign up for free
                                    </a>
                                </div>
                            </div>


                        </div>

                    </div>

                </div>

                <div class="absolute object-cover lg:object-contain lg:max-w-4xl xl:max-w-5xl flex object-cover bottom-0 left-0 z-10">
                    <img class="" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FMoonCatLearningNoBackgroundSmaller.png?alt=media&token=9eb6526c-0181-498f-bc43-80b9e0ee80fa" alt="">
                </div>
            </div>
             This example requires Tailwind CSS v2.0+
            <div class="flex flex-col h-full w-full bg-jiruto-cotton rounded-lg shadow-lg overflow-hidden block sm:hidden">
                <div class="flex-shrink-0">
                    <img class="h-48 w-full object-contain" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fovercome-obstacles-flipped.jpeg?alt=media&token=d8c34b00-98ea-4cfa-a000-f294ec08bd0d" alt="">
                </div>
                <div class="flex-1 bg-jiruto-cotton p-6 flex flex-col justify-between">
                    <div class="flex-1">
                        <a href="#" class="block mt-2">
                            <p class="text-xl font-semibold text-gray-900">
                                Online learning can be hard, especially alone
                            </p>
                            <p class="mt-3 text-base text-gray-500">
                                Mapping topics, creating a syllabus,
                                and finding good resources are serious action that can be tough as a beginner.

                            </p>
                            <br>
                            <p class="mt-3 font-bold text-jiruto-zotMain text-base text-gray-500">
                                Being a part of a community, bridges this gap.
                            </p>
                        </a>
                    </div>
<!--                    <div class="mt-6 flex items-center">-->
<!--                        <div class="flex-shrink-0">-->
<!--                            <a href="#">-->
<!--                                <span class="sr-only">Brenna Goyette</span>-->
<!--                                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixqx=dmqxMsuM0L&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <div class="ml-3">-->
<!--                            <p class="text-sm font-medium text-gray-900">-->
<!--                                <a href="#" class="hover:underline">-->
<!--                                    Brenna Goyette-->
<!--                                </a>-->
<!--                            </p>-->
<!--                            <div class="flex space-x-1 text-sm text-gray-500">-->
<!--                                <time datetime="2020-03-10">-->
<!--                                    Mar 10, 2020-->
<!--                                </time>-->
<!--                                <span aria-hidden="true">-->
<!--                                    &middot;-->
<!--                                </span>-->
<!--                                <span>-->
<!--                                    4 min read-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>

        </template>
    </flipping-card-shell>
</template>

<script>
    import FlippingCardShell from "@/pages/landing/components/FlippingCardShell";
    // import ProblemDescription from "@/pages/landing/components/ProblemDescription";
    export default {
        name: "OvercomeObstacles",
        components: { FlippingCardShell}
    }
</script>

<style scoped>

</style>
