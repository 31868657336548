<template>
    <flipping-card-shell>
        <template v-slot:cardFront>
            <div class="w-full h-full p-5 items-center flex flex-col justify-center bg-jiruto-watusi">
                <div class="absolute w-full h-full top-0 left-0 z-20 flex flex-col justify-center">
                    <div class="absolute w-full h-full top-0 left-0 z-20 flex flex-col mt-20 lg:mt-0 lg:justify-center">
                        <span class="text-6xl sm:text-7xl lg:text-8xl xl:text-9xl text-jiruto-flatRed font-bold text-center"> Discuss Openly </span>
                        <!--                        <span class="text-7xl lg:text-7xl xl:text-9xl text-white font-bold text-center mt-2">-->
                        <!--                            Learning is tough love-->
                        <!--                        </span>-->
                    </div>

                </div>

                <div class="w-full h-full flex items-end lg:items-start sm:absolute object-contain lg:object-contain max-w-md sm:max-w-xl md:max-w-xl lg:max-w-xl xl:max-w-xl 2xl:max-w-2xl flex bottom-0 right-0 lg:top-0 sm:right-0 z-10">
                    <img class="absolute object-contain flex items-end bottom-0 sm:top-0 right-0 sm:relative"
                             src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FGroupDiscussionSmaller.png?alt=media&token=8a90d559-95e0-475f-93a9-9c2c3a5cd77f" alt="">
                </div>
            </div>
        </template>

        <template v-slot:cardBack>
            <div class="w-full hidden sm:block h-full p-5 items-center flex flex-col justify-center bg-jiruto-watusi">
                <div class="absolute top-0 right-0 p-5 z-20 flex flex-col justify-center">
                    <div class="w-full h-full flex flex-col items-center">
                        <!--                        <span class="text-6xl sm:text-7xl lg:text-7xl xl:text-9xl text-jiruto-iris font-bold text-center mt-5"> Community Guidance </span>-->
                        <!--                        <span class="text-6xl sm:text-7xl lg:text-7xl xl:text-9xl text-jiruto-froly font-bold text-center mt-2">-->
                        <!--                             <it></it> makes all the difference-->
                        <!--                        </span>-->
                        <!--                        <problem-description>-->
                        <!--                        </problem-description>-->

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div class="relative">
                            <open-discussions-details></open-discussions-details>
                        </div>

                    </div>

                </div>


                <div class="absolute object-contain lg:object-contain lg:max-w-xl xl:max-w-xl 2xl:max-w-3xl  flex top-0 left-0 z-10">
                    <img class="object-contain"
                         src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FCatDonutNoBackgroundFlipped.png?alt=media&token=93ec51dc-cdba-48cb-afdc-70c0291c027b" alt="">
                </div>
            </div>
            <!-- This example requires Tailwind CSS v2.0+ -->


            <div class="flex flex-col h-full w-full bg-jiruto-watusi rounded-lg shadow-lg overflow-hidden block sm:hidden">
                <div class="flex-shrink-0">
                    <img class="h-48 w-full object-contain" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FCatDonutNoBackground.png?alt=media&token=f5c35c96-ab7a-4425-9e8a-2b15fb32d603" alt="">
                </div>
                <div class="flex-1 bg-jiruto-watusi p-6 flex flex-col justify-between">
                    <div class="flex-1">
                        <a href="#" class="block mt-2">
                            <p class="text-xl font-semibold text-gray-900">
                                Ask. Answer. Update. Discuss.
                            </p>
                            <p class="mt-3 text-base text-gray-500">
                                Use the community forum to discuss topics in context.
                            </p>
                            <p class="mt-3 text-base text-gray-500">
                                Each community has it's own powerful discussion board, keeping the communication framed and organized.
                            </p>
                        </a>
                    </div>
                    <!--                    <div class="mt-6 flex items-center">-->
                    <!--                        <div class="flex-shrink-0">-->
                    <!--                            <a href="#">-->
                    <!--                                <span class="sr-only">Brenna Goyette</span>-->
                    <!--                                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixqx=dmqxMsuM0L&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">-->
                    <!--                            </a>-->
                    <!--                        </div>-->
                    <!--                        <div class="ml-3">-->
                    <!--                            <p class="text-sm font-medium text-gray-900">-->
                    <!--                                <a href="#" class="hover:underline">-->
                    <!--                                    Brenna Goyette-->
                    <!--                                </a>-->
                    <!--                            </p>-->
                    <!--                            <div class="flex space-x-1 text-sm text-gray-500">-->
                    <!--                                <time datetime="2020-03-10">-->
                    <!--                                    Mar 10, 2020-->
                    <!--                                </time>-->
                    <!--                                <span aria-hidden="true">-->
                    <!--                                    &middot;-->
                    <!--                                </span>-->
                    <!--                                <span>-->
                    <!--                                    4 min read-->
                    <!--                                </span>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
            </div>

        </template>
    </flipping-card-shell>
</template>

<script>
    import FlippingCardShell from "@/pages/landing/components/FlippingCardShell";
    import OpenDiscussionsDetails from "@/pages/landing/components/OpenDiscussionsDetails";
    export default {
        name: "OpenDiscussions",
        components: {OpenDiscussionsDetails, FlippingCardShell}
    }
</script>

<style scoped>

</style>
