<template>
    <flipping-card-shell>
        <template v-slot:cardFront>
            <div class="w-full h-full p-5 items-center flex flex-col justify-center bg-white">
                <div class="absolute w-full h-full top-0 left-0 z-20 flex flex-col justify-center">
                    <div class="w-full h-full flex flex-col items-center mt-20">
                        <span class="text-5xl xs:text-6xl sm:text-7xl lg:text-7xl xl:text-9xl text-jiruto-zotMain font-bold text-center mt-5">Welcome To Jiruto </span>
                        <span class="text-5xl sm:text-6xl sm:text-7xl lg:text-7xl xl:text-9xl text-purple-400 font-bold text-center mt-2">
                             The Learning Community
                        </span>
                    </div>

                </div>

                <div class="w-full h-full flex items-end sm:absolute object-contain lg:object-contain max-w-md sm:max-w-xl md:max-w-xl lg:max-w-xl xl:max-w-xl 2xl:max-w-2xl flex bottom-0 left-0 z-10">
                    <img class="absolute object-contain bg-gray-200 flex items-end bottom-0 left-0 sm:relative" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FCommunity.jpeg?alt=media&token=34f0b798-a8f7-4258-942e-35fc6a79e44e" alt="">
                </div>
            </div>

        </template>

        <template v-slot:cardBack>

            <div class="w-full h-full p-5 items-center flex flex-col justify-center bg-white">
                <div class="absolute bottom-0 sm:left-0 p-10 z-20 flex flex-col justify-center">
                    <div class="w-full h-full flex flex-col items-center mt-20">
                        <span class="text-4xl sm:text-7xl lg:text-7xl xl:text-8xl text-jiruto-iris font-bold text-center mt-5"> Community Guidance </span>
                        <span class="text-4xl sm:text-7xl lg:text-7xl xl:text-8xl text-jiruto-froly font-bold text-center mt-2">
                             makes all the difference
                        </span>
                        <div class="mt-8 sm:mt-8">
                            <div class="relative">
                                <div class=" sm:mx-auto sm:max-w-lg sm:flex">
                                    <div class="min-w-0 flex-1">
                                        <label for="cta_email" class="sr-only">Email address</label>
                                        <input id="cta_email" type="email" class="block text-2xl w-full border-jiruto-zotSecondary border-gray-400 rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600" placeholder="Enter your email">
                                    </div>
                                    <div class="mt-4 sm:mt-0 sm:ml-3">
                                        <button class="block text-2xl w-full rounded-md border border-transparent px-5 py-3 bg-indigo-500 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10">Sign me up!</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="w-full h-full flex items-start sm:absolute object-contain lg:object-contain lg:max-w-2xl xl:max-w-4xl flex top-0 right-0 z-10">
                    <img class="absolute object-contain bg-gray-200 flex items-end top-0 left-0 sm:relative" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FRocketMeeting.jpeg?alt=media&token=0fc9e824-e58b-4951-ba01-fa7c4a4cf7d8" alt="">
                </div>
            </div>

        </template>
    </flipping-card-shell>
</template>

<script>
    import FlippingCardShell from "@/pages/landing/components/FlippingCardShell";
    export default {
        name: "LearningCommunity",
        components: {FlippingCardShell}
    }
</script>

<style scoped>

</style>
