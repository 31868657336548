<template>
    <div class="py-10 overflow-hidden">
        <div class="relative mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
            <div class="hidden 2xl:inline-block relative">
                <h3 class="text-center text-3xl xl:text-4xl 2xl:text-5xl leading-8 font-extrabold tracking-tight text-jiruto-zotDarker sm:text-4xl sm:leading-10">
                    Ask. Answer. Update. Discuss.
                </h3>
                <p class="mt-4 max-w-3xl mx-auto  text-center text-xl leading-7 text-jiruto-zotSecondary">
                    A powerful discussion board is at the core of each community.
                </p>
            </div>

            <div class="relative mt-3 lg:mt-7 flex flex-col xl:grid xl:grid-cols-2 lg:gap-8 items-start">
                <div class="mt-5 w-full relative lg:mt-0">
                    <img class="relative mx-auto rounded-lg opacity-80" width="490" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FJirutoDiscussions.png?alt=media&token=67db41cf-958e-43a1-9e4d-63f776466ef6" alt="" />
                    <!--                    <img class="relative mx-auto mt-4 rounded-lg opacity-80" width="490" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fintermediate-screenshot.png?alt=media&token=aee762da-5352-4746-9e0e-3fe6f1aed524" alt="" />-->
                </div>

                <div class="relative bg-gray">
                    <div class="hidden 2xl:block">
                        <h4 class="text-2xl leading-8 font-extrabold text-jiruto-froly tracking-tight sm:text-3xl sm:leading-9">
                            A central place to discuss anything. Everything.
                        </h4>
<!--                        <p class="mt-3 text-lg leading-7 text-gray-700">-->
<!--                            I don't -->
<!--                        </p>-->
                    </div>
                    <div class="block 2xl:hidden">
                        <h4 class="text-2xl leading-8 font-extrabold text-jiruto-flatRed tracking-tight sm:text-3xl sm:leading-9">
                            Ask. Answer. Update. Discuss.
                        </h4>
                        <p class="mt-3 text-lg leading-7 text-jiruto-zotSecondary">
                            A powerful discussion board is at the core of each community.
                        </p>
                    </div>

                    <ul class="mt-10">
                        <li class="featureCard ">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
<!--                                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
<!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>-->
<!--                                        </svg>-->
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                                    </div>
                                </div>
                                <div class="ml-4">
                                    <h5 class="text-lg leading-6 font-bold text-gray-700 text-left">Available & Fast</h5>
                                    <p class="mt-2 text-base leading-6 text-gray-500 text-left">
                                         Any community can deploy a forum in minutes. Gain access to one of the best discussion tools in any community.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="mt-5 featureCard ">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
<!--                                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
<!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"/>-->
<!--                                        </svg>-->
                                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 14l9-5-9-5-9 5 9 5z"></path><path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222"></path></svg>
                                    </div>
                                </div>
                                <div class="ml-4">
                                    <h5 class="text-lg leading-6 font-bold text-gray-700 text-left">Brainstorming democratization</h5>
                                    <p class="mt-2 text-base leading-6 text-gray-500 text-left">
                                        Share your thoughts as an equal. Receive feedback from your peers. Suggest and discuss ideas.
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="mt-5 hidden 2xl:inline-block featureCard">
                            <div class="flex">
                                <div class="flex-shrink-0">
                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                                        </svg>
                                    </div>
                                </div>
                                <div class="ml-4">
                                    <h5 class="text-lg leading-6 font-bold text-gray-700 text-left">Increase your understanding</h5>
                                    <p class="mt-2 text-base leading-6 text-gray-500 text-left">
                                        Use the forum to improve your knowledge. Gain access to the magnificent brainpower of your community members.
                                    </p>
                                </div>
                            </div>
                        </li>
<!--                        <li class="mt-10 hidden 2xl:inline-block">-->
<!--                            <div class="flex">-->
<!--                                <div class="flex-shrink-0">-->
<!--                                    <div class="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">-->
<!--                                        <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">-->
<!--                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"/>-->
<!--                                        </svg>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                                <div class="ml-4">-->
<!--                                    <h5 class="text-lg leading-6 font-medium text-white">Easy and straight forward</h5>-->
<!--                                    <p class="mt-2 text-base leading-6 text-gray-300">-->
<!--                                        Design learning communities with little effort. Add links, levels, description, and special explanations, all in an intuitive interface.-->
<!--                                    </p>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </li>-->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OpenDiscussionsDetails"
    }
</script>

<style scoped>
    .featureCard {
        @apply bg-white bg-opacity-80 p-3 rounded-lg
    }
</style>
