<template>
    <div class="relative w-full h-5/6 p-10 scene" >
        <div class="relative h-full w-full card-container"  :class="{'is-flipped': showDetails}">

            <div class="h-full w-full rounded-3xl card-face card-front">
                <div class="relative h-full w-full flex justify-center ">
                    <div class="absolute bouncyButton top-5 left-5">
                        <button @click="showDetailsPressed" type="button"
                                class=" absolute z-20 bg-gray-400 relative inline-flex flex-shrink-0 h-12 w-20 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                :class="{'bg-jiruto-zotSecondary': showDetails}" aria-pressed="false">
                            <span class="sr-only">Use setting</span>
                            <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                            <span aria-hidden="true" class="relative translate-x-0 pointer-events-none inline-block h-11 w-11 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                  :class="{'translate-x-8': showDetails ,'translate--0': !showDetails}"></span>
                        </button>
                    </div>

                    <div class="absolute top-0 left-0 w-full h-full rounded-3xl z-10 overflow-hidden">
                        <slot name="cardFront"> </slot>
                    </div>
                </div>
            </div>

            <div class="h-full w-full rounded-3xl card-face card-back">
                <div class="relative h-full w-full flex justify-center ">
                    <div class="absolute top-5 left-5">
                        <button @click="showDetailsPressed" type="button"
                                class="absolute z-20 bg-gray-400 relative inline-flex flex-shrink-0 h-12 w-20 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                :class="{'bg-jiruto-zotSecondary': showDetails}" aria-pressed="false">
                            <span class="sr-only">Use setting</span>
                            <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                            <span aria-hidden="true" class="relative translate-x-0 pointer-events-none inline-block h-11 w-11 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                  :class="{'translate-x-8': showDetails ,'translate--0': !showDetails}"></span>
                        </button>
                    </div>

                    <div class="absolute top-0 left-0 w-full h-full rounded-3xl z-10 overflow-hidden">
                        <slot name="cardBack"> </slot>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "FlippingCardShell",
        data(){
            return {
                showDetails: false,
            }
        },
        methods: {
            showDetailsPressed(){
                this.showDetails = !this.showDetails
            }
        }
    }
</script>

<style scoped>

    .scene {
        perspective: 8000px;
    }

    .card-container {
        /*width: 100%;*/
        /*height: 100%;*/
        transition: transform 1s;
        transform-style: preserve-3d;
        /*cursor: pointer;*/
        position: relative;
    }

    .card-container.is-flipped {
        transform: rotateY(180deg);
    }

    .card-face {
        position: absolute;
        /*width: 100%;*/
        /*height: 100%;*/
        /*line-height: 260px;*/
        color: white;
        text-align: center;
        font-weight: bold;
        font-size: 40px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform: translateZ(1px);

    }

    .card-back {
        transform: rotateY(-180deg) translateZ(1px);
        -webkit-backface-visibility: hidden;
        -webkit-transform: rotateY(180deg) translateZ(1px);
    }

    .bouncyButton {
        animation: headShake;
        animation-duration: 5s;
    }

</style>
