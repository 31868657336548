<template>
    <flipping-card-shell>
        <template v-slot:cardFront>
            <div class="w-full h-full p-5 items-center flex flex-col justify-center bg-jiruto-deepBlush">
                <div class="absolute w-full h-full top-0 left-0 z-20 flex flex-col justify-center">
                    <div class="absolute w-full h-full top-0 left-0 z-20 flex flex-col mt-20 lg:mt-0 lg:justify-center">
                        <span class="text-6xl sm:text-7xl lg:text-8xl xl:text-9xl text-jiruto-main font-bold text-center"> Learning Is The Door </span>
                        <!--                        <span class="text-7xl lg:text-7xl xl:text-9xl text-white font-bold text-center mt-2">-->
                        <!--                            Learning is tough love-->
                        <!--                        </span>-->
                    </div>
                </div>

                <div class=" w-full h-full flex items-end sm:absolute object-contain lg:object-contain max-w-md sm:max-w-2xl md:max-w-2xl lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl 3xl:max-w-3xl flex bottom-0 right-0 z-10">
                    <img class="absolute object-contain flex items-end bottom-0 right-0 sm:relative"
                         src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FDreamDoor.jpeg?alt=media&token=e3422ef7-fe91-4865-bf75-68b7eb6e8d23" alt="">
                </div>
            </div>
        </template>

        <template v-slot:cardBack>
            <div class="w-full hidden sm:block h-full p-5 items-center flex flex-col justify-center bg-jiruto-deepBlush">
                <div class="absolute top-0 left-0 p-5 z-20 flex flex-col justify-center">
                    <div class="w-full h-full flex flex-col items-center 2xl:mt-10 ">
                        <your-dream class=""></your-dream>
                    </div>
                </div>

                <div class="absolute object-contain lg:max-w-4xl xl:max-w-4xl flex bottom-0 right-0 z-10">
                    <img class="object-contain" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FCatHousesmaller-squashed.png?alt=media&token=0793c440-17e0-432f-ad22-c4e26f124fc4" alt="">
                </div>
            </div>
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="flex flex-col h-full w-full bg-jiruto-deepBlush rounded-lg shadow-lg overflow-hidden block sm:hidden">
                <div class="flex-shrink-0">
                    <img class="h-48 mt-5 w-full object-contain"
                         src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FCatHousesmaller-squashed.png?alt=media&token=0793c440-17e0-432f-ad22-c4e26f124fc4" alt="">
                </div>
                <div class="flex-1 bg-jiruto-deepBlush flex flex-col justify-between">
                    <your-dream class="px-2"></your-dream>
<!--                    <div class="flex-1">-->
<!--                        <a href="#" class="block mt-2">-->
<!--                            <p class="text-xl font-semibold text-gray-900">-->
<!--                                Brainstorming, lectures, and so much more-->
<!--                            </p>-->
<!--                            <p class="mt-3 text-base text-gray-500">-->
<!--                                Live discussions help you gain deeper understanding, exit the echo chamber, and meet new and interesting people.-->
<!--                            </p>-->
<!--                            <p class="mt-3 text-base text-gray-500">-->
<!--                                Communities in Jiruto can host lectures, divide into break-rooms, and help members find co-learners-->
<!--                            </p>-->
<!--                        </a>-->
<!--                    </div>-->
                    <!--                    <div class="mt-6 flex items-center">-->
                    <!--                        <div class="flex-shrink-0">-->
                    <!--                            <a href="#">-->
                    <!--                                <span class="sr-only">Brenna Goyette</span>-->
                    <!--                                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixqx=dmqxMsuM0L&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">-->
                    <!--                            </a>-->
                    <!--                        </div>-->
                    <!--                        <div class="ml-3">-->
                    <!--                            <p class="text-sm font-medium text-gray-900">-->
                    <!--                                <a href="#" class="hover:underline">-->
                    <!--                                    Brenna Goyette-->
                    <!--                                </a>-->
                    <!--                            </p>-->
                    <!--                            <div class="flex space-x-1 text-sm text-gray-500">-->
                    <!--                                <time datetime="2020-03-10">-->
                    <!--                                    Mar 10, 2020-->
                    <!--                                </time>-->
                    <!--                                <span aria-hidden="true">-->
                    <!--                                    &middot;-->
                    <!--                                </span>-->
                    <!--                                <span>-->
                    <!--                                    4 min read-->
                    <!--                                </span>-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </div>-->
                </div>
            </div>

        </template>
    </flipping-card-shell>
</template>

<script>
    import FlippingCardShell from "@/pages/landing/components/FlippingCardShell";
    import YourDream from "@/pages/landing/components/YourDream";
    export default {
        name: "DreamDoors",
        components: {YourDream, FlippingCardShell}
    }
</script>

<style scoped>

</style>
