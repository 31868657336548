<template>
    <flipping-card-shell>
        <template v-slot:cardFront>
            <div class="w-full h-full p-5 items-center flex flex-col justify-center bg-jiruto-cotton">
                <div class="absolute w-full h-full top-0 left-0 z-20 flex flex-col justify-center">
                    <div class="absolute w-full h-full top-0 left-0 z-20 flex flex-col mt-20 lg:mt-0 lg:justify-center">
                        <span class="text-6xl sm:text-7xl lg:text-8xl xl:text-9xl text-teal-500 font-bold text-center"> Overcome Obstacles </span>
<!--                        <span class="text-7xl lg:text-7xl xl:text-9xl text-white font-bold text-center mt-2">-->
<!--                            Learning is tough love-->
<!--                        </span>-->
                    </div>

                </div>

                <div class="w-full h-full flex items-end sm:absolute object-contain max-w-md sm:max-w-xl md:max-w-xl lg:max-w-xl xl:max-w-lg 2xl:max-w-2xl flex bottom-0 right-0 z-10">
                    <img class="absolute object-contain flex items-end bottom-0 right-0 sm:relative bg-jiruto-cotton" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2FGirlWonderingNoBackground.png?alt=media&token=8b1f5f0a-54d0-4f71-846b-f6b6a3abc560" alt="">
                </div>
            </div>
        </template>

        <template v-slot:cardBack>
            <div class="w-full hidden sm:block h-full p-5 items-center flex flex-col justify-center bg-jiruto-cotton">
                <div class="absolute top-0 right-0 p-5 z-20 flex flex-col justify-center">
                    <div class="w-full h-full flex flex-col items-center">
<!--                        <span class="text-6xl sm:text-7xl lg:text-7xl xl:text-9xl text-jiruto-iris font-bold text-center mt-5"> Community Guidance </span>-->
<!--                        <span class="text-6xl sm:text-7xl lg:text-7xl xl:text-9xl text-jiruto-froly font-bold text-center mt-2">-->
<!--                             <it></it> makes all the difference-->
<!--                        </span>-->
<!--                        <problem-description>-->
<!--                        </problem-description>-->

                        <!-- This example requires Tailwind CSS v2.0+ -->
                        <div class="relative">
                            <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
<!--                                <h2 class="text-base font-semibold tracking-wider text-indigo-600 uppercase hidden lg:block">Deploy faster</h2>-->
                                <p class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-6xl hidden 2xl:block">
                                    Learning Can Be Hard
                                </p>
                                <p class="mt-5 max-w-prose mx-auto text-xl text-gray-800 2xl:text-gray-800 hidden 2xl:block">
                                    Leverage a strong community. Ask. Discuss. Brainstorm.
                                </p>
                                <div class="mt-5">
                                    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                                        <div class="pt-6">
                                            <div class="flow-root bg-opacity-80 bg-jiruto-perfume rounded-lg px-6 pb-8">
                                                <div class="-mt-6">
                                                    <div>
                                                        <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                                                          <!-- Heroicon name: outline/cloud-upload -->
                                                          <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                                          </svg>
                                                        </span>
                                                    </div>
                                                    <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight">Mapping topics</h3>
                                                    <p class="mt-5 text-base featureText">
                                                        Knowing your way around a new subject can be challenging. Without a guiding hand it can waste a lot of time.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="pt-6">
                                            <div class="flow-root bg-opacity-80 bg-jiruto-perfume rounded-lg px-6 pb-8">
                                                <div class="-mt-6">
                                                    <div>
                                                        <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                                                          <!-- Heroicon name: outline/refresh -->
                                                          <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                                                          </svg>
                                                        </span>
                                                    </div>
                                                    <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight">Finding Resources</h3>
                                                    <p class="mt-5 text-base featureText">
                                                        There are so many out there. We don't want to waste our time, we want the good ones. How do we find them?
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="pt-6">
                                            <div class="flow-root  bg-jiruto-perfume bg-opacity-80 rounded-lg px-6 pb-8">
                                                <div class="-mt-6">
                                                    <div>
                                                        <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                                                          <!-- Heroicon name: outline/lock-closed -->
                                                          <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                                          </svg>
                                                        </span>
                                                    </div>
                                                    <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight">Designing Curriculums</h3>
                                                    <p class="mt-5 text-base featureText">
                                                        Which resources will go into the curriculum and which won't? In which order should I place them?
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="pt-6">
                                            <div class="flow-root bg-opacity-80 bg-jiruto-perfume rounded-lg px-6 pb-8">
                                                <div class="-mt-6">
                                                    <div>
                                                        <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                                                          <!-- Heroicon name: outline/shield-check -->
                                                          <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                                                          </svg>
                                                        </span>
                                                    </div>
                                                    <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight">Finding Peers</h3>
                                                    <p class="mt-5 text-base featureText">
                                                        Where do we find more people who share our interests? How do we engage with them? Where?
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="pt-6">
                                            <div class="flow-root bg-opacity-80 bg-jiruto-perfume rounded-lg px-6 pb-8">
                                                <div class="-mt-6 ">
                                                    <div>
                                                        <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                                                          <!-- Heroicon name: outline/cog -->
                                                          <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                          </svg>
                                                        </span>
                                                    </div>
                                                    <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight">Lack Of Structure</h3>
                                                    <p class="mt-5 text-base featureText">
                                                        Structure gives us the confidence to go forward, how do we operate when it's not there?
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="pt-6">
                                            <div class="flow-root bg-opacity-80 bg-jiruto-perfume rounded-lg px-6 pb-8">
                                                <div class="-mt-6">
                                                    <div>
                                                        <span class="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                                                          <!-- Heroicon name: outline/server -->
                                                          <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
                                                          </svg>
                                                        </span>
                                                    </div>
                                                    <h3 class="mt-8 text-xl font-medium text-gray-900 tracking-tight">Making friends</h3>
                                                    <p class="mt-5 text-base featureText">
                                                        Self learning can get lonesome, but it doesn't have to be, we can make it a group activity.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div class="absolute object-cover lg:object-contain lg:max-w-4xl xl:max-w-6xl flex object-cover bottom-0 left-0 z-10">
                    <img class="" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fovercome-obstacles-flipped.jpeg?alt=media&token=d8c34b00-98ea-4cfa-a000-f294ec08bd0d" alt="">
                </div>
            </div>
            <!-- This example requires Tailwind CSS v2.0+ -->
            <div class="flex flex-col h-full w-full bg-jiruto-cotton rounded-lg shadow-lg overflow-hidden block sm:hidden">
                <div class="flex-shrink-0">
                    <img class="h-48 w-full object-contain" src="https://firebasestorage.googleapis.com/v0/b/jiruto-1bee2.appspot.com/o/public%2Fassets%2Fovercome-obstacles-flipped.jpeg?alt=media&token=d8c34b00-98ea-4cfa-a000-f294ec08bd0d" alt="">
                </div>
                <div class="flex-1 bg-jiruto-cotton p-6 flex flex-col justify-between">
                    <div class="flex-1">
                        <a href="#" class="block mt-2">
                            <p class="text-xl font-semibold text-gray-900">
                                Online learning can be hard, especially alone
                            </p>
                            <p class="mt-3 text-base text-gray-500">
                                Mapping topics, creating a syllabus,
                                and finding good resources are serious action that can be tough as a beginner.

                            </p>
                            <br>
                            <p class="mt-3 font-bold text-jiruto-zotMain text-base text-gray-500">
                                Being a part of a community, bridges this gap.
                            </p>
                        </a>
                    </div>
<!--                    <div class="mt-6 flex items-center">-->
<!--                        <div class="flex-shrink-0">-->
<!--                            <a href="#">-->
<!--                                <span class="sr-only">Brenna Goyette</span>-->
<!--                                <img class="h-10 w-10 rounded-full" src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixqx=dmqxMsuM0L&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="">-->
<!--                            </a>-->
<!--                        </div>-->
<!--                        <div class="ml-3">-->
<!--                            <p class="text-sm font-medium text-gray-900">-->
<!--                                <a href="#" class="hover:underline">-->
<!--                                    Brenna Goyette-->
<!--                                </a>-->
<!--                            </p>-->
<!--                            <div class="flex space-x-1 text-sm text-gray-500">-->
<!--                                <time datetime="2020-03-10">-->
<!--                                    Mar 10, 2020-->
<!--                                </time>-->
<!--                                <span aria-hidden="true">-->
<!--                                    &middot;-->
<!--                                </span>-->
<!--                                <span>-->
<!--                                    4 min read-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
                </div>
            </div>

        </template>
    </flipping-card-shell>
</template>

<script>
    import FlippingCardShell from "@/pages/landing/components/FlippingCardShell";
    // import ProblemDescription from "@/pages/landing/components/ProblemDescription";
    export default {
        name: "OvercomeObstacles",
        components: { FlippingCardShell}
    }
</script>

<style scoped>
    .featureText {
        @apply text-gray-700
    }

</style>
